// import { ElConfigProvider } from 'element-plus'
// import zhCn from 'element-plus/lib/locale/lang/zh-cn'
//  import zhCn from "../node_modules/element-plus/lib/locale/lang/zh-cn"; 
import exp from "./components/expenses.vue";
import home from "./components/navi.vue";
import falv from "./components/falv.vue";
export default {
  components: {
    home,
    exp,
    falv
  }
};
function adapter() {
  //获取布局视口宽度，计算跟字体大小，设置跟字体大小
  const dpwidth = document.documentElement.clientWidth;
  const rootFontSize = dpwidth * 100 / 2560;
  document.documentElement.style.fontSize = rootFontSize + 'px';
}
adapter();
window.onresize = () => {
  adapter();
};