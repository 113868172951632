import { createApp } from 'vue'
import App from './App.vue'

// import ElementPlus from 'element-plus'
// import zhCn from 'element-plus/lib/locale/lang/zh-cn'
// import locale from "/node_modules/element-plus/lib/locale/lang/zh-cn.js"
// import 'element-plus/dist/index.css'
// import locale from "element-plus/lib/locale/lang/zh-cn"
// src="//unpkg.com/element-plus/dist/locale/zh-cn"
// import 'element-plus/theme-chalk/dark/css-vars.css'


// app.use(ElementPlus, { locale })
createApp(App).mount('#app')
