import "core-js/modules/es.array.push.js";
import { computed, ref, reactive } from 'vue';
export default {
  setup() {
    const tableData = [{
      jine: '100万以下',
      leix: '1.5%',
      leix2: '1.5%',
      leix3: '1.0%'
    }, {
      jine: '100-500万',
      leix: '1.1%',
      leix2: '0.8%',
      leix3: '0.7%'
    }, {
      jine: '500-1000万',
      leix: '0.8%',
      leix2: '0.45%',
      leix3: '0.55%'
    }, {
      jine: '1000-5000万',
      leix: '0.5%',
      leix2: '0.25%',
      leix3: '0.35%'
    }, {
      jine: '5000-10000万',
      leix: '0.25%',
      leix2: '0.1%',
      leix3: '0.2%'
    }, {
      jine: '10000-100000万',
      leix: '0.05%',
      leix2: '0.05%',
      leix3: '0.05%'
    }, {
      jine: '100000万以上',
      leix: '0.01%',
      leix2: '0.01%',
      leix3: '0.01%'
    }];
    const input = ref('');
    const radio = ref(3);
    // const exp2 =input.value*12.5
    const exp5 = ref(input.value);
    const exp3 = ref(0);
    const menyy = [1000000, 5000000, 10000000, 50000000, 100000000, 1000000000, 10000000000];
    // const meny1 = ref(0)
    // const exp3 = computed(() => {
    //     return input.value * 0.01
    // })
    const text1 = ref();
    const tableData1 = reactive([{
      jiage: '100万以下',
      gongs: '1.5%',
      jieguo: '1.5%'
    }]);
    function exp4() {
      if (radio.value == "3") {
        if (input.value <= 1000000) {
          let sum = (input.value * 0.01).toFixed(2);
          exp3.value = sum;
          tableData1.length = 0;
          tableData1.push({
            jiage: "100万以下",
            zbj: input.value,
            gongs: input.value + "*" + "0.1%" + "=" + sum,
            jieguo: sum
          });
        }
        if (input.value > 1000000 && input.value <= 5000000) {
          tableData1.length = 0;
          tableData1.push({
            jiage: "",
            zbj: "",
            gongs: "",
            jieguo: ""
          });
          let sum1 = input.value - 1000000;
          let sum = (1000000 * 0.01 + sum1 * 0.007).toFixed(2);
          exp3.value = sum;
          tableData1.length = 0;
          tableData1[0].jiage = "100万";
          tableData1[0].gongs = 1000000 + "*" + "1%" + "=" + 1000000 * 0.01;
          tableData1[0].jieguo = 1000000 * 0.01;
          tableData1[0].zbj = 1000000;
          tableData1.push({
            jiage: "100万-500万",
            zbj: sum1,
            gongs: sum1 + "*" + "0.7%" + "=" + sum1 * 0.007,
            jieguo: sum1 * 0.007
          });
          tableData1.push({
            jiage: "中标价格",
            zbj: input.value,
            gongs: "合计",
            jieguo: sum
          });
        }
        if (input.value > 5000000 && input.value <= 10000000) {
          let sum1 = 1000000;
          let sum2 = 4000000;
          let sum3 = input.value - 5000000;
          let sum = (sum1 * 0.01 + sum2 * 0.007 + sum3 * 0.0055).toFixed(2);
          exp3.value = sum;
          tableData1.length = 0;
          tableData1.push({
            jiage: "",
            zbj: "",
            gongs: "",
            jieguo: ""
          });
          tableData1[0].jiage = "100万";
          tableData1[0].gongs = 1000000 + "*" + "1%" + "=" + 1000000 * 0.01;
          tableData1[0].jieguo = 1000000 * 0.01;
          tableData1[0].zbj = 1000000;
          tableData1.push({
            jiage: "100-500万",
            zbj: sum2,
            gongs: 4000000 + "*" + "0.7%" + "=" + 4000000 * 0.007,
            jieguo: 4000000 * 0.007
          });
          tableData1.push({
            jiage: "500-1000万",
            zbj: sum3,
            gongs: sum3 + "*" + "0.55%" + "=" + sum3 * 0.0055,
            jieguo: sum3 * 0.0055
          });
          tableData1.push({
            jiage: "中标价格",
            zbj: input.value,
            gongs: "合计",
            jieguo: sum
          });
        }
        if (input.value > 10000000 && input.value <= 50000000) {
          tableData1.length = 0;
          const sum1 = 1000000;
          const sum2 = 4000000;
          const sum3 = 5000000;
          const sum4 = input.value - 10000000;
          const sum = (sum1 * 0.01 + sum2 * 0.007 + sum3 * 0.0055 + sum4 * 0.0035).toFixed(2);
          exp3.value = sum;
          tableData1.push({
            jiage: "100万",
            zbj: "1000000",
            gongs: sum1 + "*" + "1%" + "=" + sum1 * 0.01,
            jieguo: sum1 * 0.01
          });
          tableData1.push({
            jiage: "100-500万",
            zbj: sum2,
            gongs: 4000000 + "*" + "0.7%" + "=" + 4000000 * 0.007,
            jieguo: 4000000 * 0.007
          });
          tableData1.push({
            jiage: "500-1000万",
            zbj: sum3,
            gongs: sum3 + "*" + "0.55%" + "=" + sum3 * 0.0055,
            jieguo: sum3 * 0.0055
          });
          tableData1.push({
            jiage: "1000-5000万",
            zbj: sum4,
            gongs: sum4 + "*" + "0.35%" + "=" + sum4 * 0.0035,
            jieguo: sum4 * 0.0035
          });
          tableData1.push({
            jiage: "中标价格",
            zbj: input.value,
            gongs: "合计",
            jieguo: sum
          });
        }
        if (input.value > 50000000 && input.value <= 100000000) {
          tableData1.length = 0;
          const sum1 = 1000000;
          const sum2 = 4000000;
          const sum3 = 5000000;
          const sum4 = 40000000;
          const sum5 = input.value - 50000000;
          const sum = (sum1 * 0.01 + sum2 * 0.007 + sum3 * 0.0055 + sum4 * 0.0035 + sum5 * 0.002).toFixed(2);
          exp3.value = sum;
          tableData1.push({
            jiage: "100万",
            zbj: "1000000",
            gongs: sum1 + "*" + "1%" + "=" + sum1 * 0.01,
            jieguo: sum1 * 0.01
          });
          tableData1.push({
            jiage: "100-500万",
            zbj: sum2,
            gongs: 4000000 + "*" + "0.7%" + "=" + 4000000 * 0.007,
            jieguo: 4000000 * 0.007
          });
          tableData1.push({
            jiage: "500-1000万",
            zbj: sum3,
            gongs: sum3 + "*" + "0.55%" + "=" + sum3 * 0.0055,
            jieguo: sum3 * 0.0055
          });
          tableData1.push({
            jiage: "1000-5000万",
            zbj: sum4,
            gongs: sum4 + "*" + "0.35%" + "=" + sum4 * 0.0035,
            jieguo: sum4 * 0.0035
          });
          tableData1.push({
            jiage: "5000-10000万",
            zbj: sum5,
            gongs: sum5 + "*" + "0.2%" + "=" + sum5 * 0.002,
            jieguo: sum5 * 0.002
          });
          tableData1.push({
            jiage: "中标价格",
            zbj: input.value,
            gongs: "合计",
            jieguo: sum
          });
        }
        if (input.value > 100000000 && input.value <= 500000000) {
          tableData1.length = 0;
          const sum1 = 1000000;
          const sum2 = 4000000;
          const sum3 = 5000000;
          const sum4 = 40000000;
          const sum5 = 50000000;
          const sum6 = input.value - 100000000;
          const sum = (sum1 * 0.01 + sum2 * 0.007 + sum3 * 0.0055 + sum4 * 0.0035 + sum5 * 0.002 + sum6 * 0.0005).toFixed(2);
          exp3.value = sum;
          tableData1.push({
            jiage: "100万",
            zbj: "1000000",
            gongs: sum1 + "*" + "1%" + "=" + sum1 * 0.01,
            jieguo: sum1 * 0.01
          });
          tableData1.push({
            jiage: "100-500万",
            zbj: sum2,
            gongs: 4000000 + "*" + "0.7%" + "=" + 4000000 * 0.007,
            jieguo: 4000000 * 0.007
          });
          tableData1.push({
            jiage: "500-1000万",
            zbj: sum3,
            gongs: sum3 + "*" + "0.55%" + "=" + sum3 * 0.0055,
            jieguo: sum3 * 0.0055
          });
          tableData1.push({
            jiage: "1000-5000万",
            zbj: sum4,
            gongs: sum4 + "*" + "0.35%" + "=" + sum4 * 0.0035,
            jieguo: sum4 * 0.0035
          });
          tableData1.push({
            jiage: "5000-10000万",
            zbj: sum5,
            gongs: sum5 + "*" + "0.2%" + "=" + sum5 * 0.002,
            jieguo: sum5 * 0.002
          });
          tableData1.push({
            jiage: "50000-100000万",
            zbj: sum6,
            gongs: sum6 + "*" + "0.05%" + "=" + sum6 * 0.0005,
            jieguo: sum6 * 0.0005
          });
          tableData1.push({
            jiage: "中标价格",
            zbj: input.value,
            gongs: "合计",
            jieguo: sum
          });
        }
        if (input.value > 500000000 && input.value <= 1000000000) {
          tableData1.length = 0;
          const sum1 = 1000000;
          const sum2 = 4000000;
          const sum3 = 5000000;
          const sum4 = 40000000;
          const sum5 = 50000000;
          const sum6 = 400000000;
          const sum7 = input.value - 500000000;
          const sum = (sum1 * 0.01 + sum2 * 0.007 + sum3 * 0.0055 + sum4 * 0.0035 + sum5 * 0.002 + sum6 * 0.0005 + sum7 * 0.00035).toFixed(2);
          exp3.value = sum;
          tableData1.push({
            jiage: "100万",
            zbj: "1000000",
            gongs: sum1 + "*" + "1%" + "=" + sum1 * 0.01,
            jieguo: sum1 * 0.01
          });
          tableData1.push({
            jiage: "100-500万",
            zbj: sum2,
            gongs: 4000000 + "*" + "0.7%" + "=" + 4000000 * 0.007,
            jieguo: 4000000 * 0.007
          });
          tableData1.push({
            jiage: "500-1000万",
            zbj: sum3,
            gongs: sum3 + "*" + "0.55%" + "=" + sum3 * 0.0055,
            jieguo: sum3 * 0.0055
          });
          tableData1.push({
            jiage: "1000-5000万",
            zbj: sum4,
            gongs: sum4 + "*" + "0.35%" + "=" + sum4 * 0.0035,
            jieguo: sum4 * 0.0035
          });
          tableData1.push({
            jiage: "5000-10000万",
            zbj: sum5,
            gongs: sum5 + "*" + "0.2%" + "=" + sum5 * 0.002,
            jieguo: sum5 * 0.002
          });
          tableData1.push({
            jiage: "10000-500000万",
            zbj: sum6,
            gongs: sum6 + "*" + "0.05%" + "=" + sum6 * 0.0005,
            jieguo: sum6 * 0.0005
          });
          tableData1.push({
            jiage: "50000-100000万",
            zbj: sum7,
            gongs: sum7 + "*" + "0.035%" + "=" + sum7 * 0.00035,
            jieguo: sum7 * 0.00035
          });
          tableData1.push({
            jiage: "中标价格",
            zbj: input.value,
            gongs: "合计",
            jieguo: sum
          });
        }
        if (input.value > 1000000000 && input.value <= 5000000000) {
          tableData1.length = 0;
          const sum1 = 1000000;
          const sum2 = 4000000;
          const sum3 = 5000000;
          const sum4 = 40000000;
          const sum5 = 50000000;
          const sum6 = 400000000;
          const sum7 = 500000000;
          const sum8 = input.value - 1000000000;
          const sum = (sum1 * 0.01 + sum2 * 0.007 + sum3 * 0.0055 + sum4 * 0.0035 + sum5 * 0.002 + sum6 * 0.0005 + sum7 * 0.00035 + sum8 * 0.00008).toFixed(2);
          exp3.value = sum;
          tableData1.push({
            jiage: "100万",
            zbj: "1000000",
            gongs: sum1 + "*" + "1%" + "=" + sum1 * 0.01,
            jieguo: sum1 * 0.01
          });
          tableData1.push({
            jiage: "100-500万",
            zbj: sum2,
            gongs: 4000000 + "*" + "0.7%" + "=" + 4000000 * 0.007,
            jieguo: 4000000 * 0.007
          });
          tableData1.push({
            jiage: "500-1000万",
            zbj: sum3,
            gongs: sum3 + "*" + "0.55%" + "=" + sum3 * 0.0055,
            jieguo: sum3 * 0.0055
          });
          tableData1.push({
            jiage: "1000-5000万",
            zbj: sum4,
            gongs: sum4 + "*" + "0.35%" + "=" + sum4 * 0.0035,
            jieguo: sum4 * 0.0035
          });
          tableData1.push({
            jiage: "5000-10000万",
            zbj: sum5,
            gongs: sum5 + "*" + "0.2%" + "=" + sum5 * 0.002,
            jieguo: sum5 * 0.002
          });
          tableData1.push({
            jiage: "10000-500000万",
            zbj: sum6,
            gongs: sum6 + "*" + "0.05%" + "=" + sum6 * 0.0005,
            jieguo: sum6 * 0.0005
          });
          tableData1.push({
            jiage: "50000-100000万",
            zbj: sum7,
            gongs: sum7 + "*" + "0.035%" + "=" + sum7 * 0.00035,
            jieguo: sum7 * 0.00035
          });
          tableData1.push({
            jiage: "100000-500000万",
            zbj: sum8,
            gongs: sum8 + "*" + "0.008%" + "=" + sum8 * 0.00008,
            jieguo: sum8 * 0.00008
          });
          tableData1.push({
            jiage: "中标价格",
            zbj: input.value,
            gongs: "合计",
            jieguo: sum
          });
        }
        if (input.value > 5000000000 && input.value <= 10000000000) {
          const sum1 = 1000000;
          const sum2 = 4000000;
          const sum3 = 5000000;
          const sum4 = 40000000;
          const sum5 = 50000000;
          const sum6 = 400000000;
          const sum7 = 500000000;
          const sum8 = 4000000000;
          const sum9 = input.value - 5000000000;
          const sum = (sum1 * 0.01 + sum2 * 0.007 + sum3 * 0.0055 + sum4 * 0.0035 + sum5 * 0.002 + sum6 * 0.0005 + sum7 * 0.00035 + sum8 * 0.00008 + sum9 * 0.00006).toFixed(2);
          exp3.value = sum;
          tableData1.length = 0;
          tableData1.push({
            jiage: "100万",
            zbj: "1000000",
            gongs: sum1 + "*" + "1%" + "=" + sum1 * 0.01,
            jieguo: sum1 * 0.01
          });
          tableData1.push({
            jiage: "100-500万",
            zbj: sum2,
            gongs: 4000000 + "*" + "0.7%" + "=" + 4000000 * 0.007,
            jieguo: 4000000 * 0.007
          });
          tableData1.push({
            jiage: "500-1000万",
            zbj: sum3,
            gongs: sum3 + "*" + "0.55%" + "=" + sum3 * 0.0055,
            jieguo: sum3 * 0.0055
          });
          tableData1.push({
            jiage: "1000-5000万",
            zbj: sum4,
            gongs: sum4 + "*" + "0.35%" + "=" + sum4 * 0.0035,
            jieguo: sum4 * 0.0035
          });
          tableData1.push({
            jiage: "5000-10000万",
            zbj: sum5,
            gongs: sum5 + "*" + "0.2%" + "=" + sum5 * 0.002,
            jieguo: sum5 * 0.002
          });
          tableData1.push({
            jiage: "10000-500000万",
            zbj: sum6,
            gongs: sum6 + "*" + "0.05%" + "=" + sum6 * 0.0005,
            jieguo: sum6 * 0.0005
          });
          tableData1.push({
            jiage: "50000-100000万",
            zbj: sum7,
            gongs: sum7 + "*" + "0.035%" + "=" + sum7 * 0.00035,
            jieguo: sum7 * 0.00035
          });
          tableData1.push({
            jiage: "100000-500000万",
            zbj: sum8,
            gongs: sum8 + "*" + "0.008%" + "=" + sum8 * 0.00008,
            jieguo: sum8 * 0.00008
          });
          tableData1.push({
            jiage: "500000-1000000万",
            zbj: sum9,
            gongs: sum9 + "*" + "0.006%" + "=" + sum9 * 0.00006,
            jieguo: sum9 * 0.00006
          });
          tableData1.push({
            jiage: "中标价格",
            zbj: input.value,
            gongs: "合计",
            jieguo: sum
          });
        }
        if (input.value > 10000000000) {
          const sum1 = 1000000;
          const sum2 = 4000000;
          const sum3 = 5000000;
          const sum4 = 40000000;
          const sum5 = 50000000;
          const sum6 = 400000000;
          const sum7 = 500000000;
          const sum8 = 4000000000;
          const sum9 = 5000000000;
          const sum10 = input.value - 10000000000;
          const sum = (sum1 * 0.01 + sum2 * 0.007 + sum3 * 0.0055 + sum4 * 0.0035 + sum5 * 0.002 + sum6 * 0.0005 + sum7 * 0.00035 + sum8 * 0.00008 + sum9 * 0.00006 + sum10 * 0.00004).toFixed(2);
          if (sum >= 4500000) {
            exp3.value = 4500000;
            console.log(311111);
          } else {
            exp3.value = sum;
          }
          tableData1.length = 0;
          tableData1.push({
            jiage: "100万",
            zbj: "1000000",
            gongs: sum1 + "*" + "1%" + "=" + sum1 * 0.01,
            jieguo: sum1 * 0.01
          });
          tableData1.push({
            jiage: "100-500万",
            zbj: sum2,
            gongs: 4000000 + "*" + "0.7%" + "=" + 4000000 * 0.007,
            jieguo: 4000000 * 0.007
          });
          tableData1.push({
            jiage: "500-1000万",
            zbj: sum3,
            gongs: sum3 + "*" + "0.55%" + "=" + sum3 * 0.0055,
            jieguo: sum3 * 0.0055
          });
          tableData1.push({
            jiage: "1000-5000万",
            zbj: sum4,
            gongs: sum4 + "*" + "0.35%" + "=" + sum4 * 0.0035,
            jieguo: sum4 * 0.0035
          });
          tableData1.push({
            jiage: "5000-10000万",
            zbj: sum5,
            gongs: sum5 + "*" + "0.2%" + "=" + sum5 * 0.002,
            jieguo: sum5 * 0.002
          });
          tableData1.push({
            jiage: "10000-500000万",
            zbj: sum6,
            gongs: sum6 + "*" + "0.05%" + "=" + sum6 * 0.0005,
            jieguo: sum6 * 0.0005
          });
          tableData1.push({
            jiage: "50000-100000万",
            zbj: sum7,
            gongs: sum7 + "*" + "0.035%" + "=" + sum7 * 0.00035,
            jieguo: sum7 * 0.00035
          });
          tableData1.push({
            jiage: "100000-500000万",
            zbj: sum8,
            gongs: sum8 + "*" + "0.008%" + "=" + sum8 * 0.00008,
            jieguo: sum8 * 0.00008
          });
          tableData1.push({
            jiage: "500000-1000000万",
            zbj: sum9,
            gongs: sum9 + "*" + "0.006%" + "=" + sum9 * 0.00006,
            jieguo: sum9 * 0.00006
          });
          tableData1.push({
            jiage: "1000000万以上",
            zbj: sum10,
            gongs: sum10 + "*" + "0.004%" + "=" + sum10 * 0.00004,
            jieguo: sum10 * 0.00004
          });
          tableData1.push({
            jiage: "中标价格",
            zbj: input.value,
            gongs: "合计",
            jieguo: sum
          });
        }

        // console.log(111111);
      } else if (radio.value == "6") {
        if (input.value <= 1000000) {
          let sum = (input.value * 0.015).toFixed(2);
          exp3.value = sum;
          tableData1.length = 0;
          tableData1.push({
            jiage: "100万以下",
            zbj: input.value,
            gongs: input.value + "*" + "1.5%" + "=" + sum,
            jieguo: sum
          });
        }
        if (input.value > 1000000 && input.value <= 5000000) {
          let sum1 = input.value - 1000000;
          let sum = (1000000 * 0.015 + sum1 * 0.008).toFixed(2);
          exp3.value = sum;
          tableData1.length = 0;
          tableData1.push({
            jiage: "100万",
            zbj: "1000000",
            gongs: 1000000 + "*" + "1.5%" + "=" + 1000000 * 0.015,
            jieguo: 1000000 * 0.015
          });
          tableData1.push({
            jiage: "100万-500万",
            zbj: sum1,
            gongs: sum1 + "*" + "0.8%" + "=" + sum1 * 0.008,
            jieguo: sum1 * 0.008
          });
          tableData1.push({
            jiage: "中标价格",
            zbj: input.value,
            gongs: "合计",
            jieguo: sum
          });
        }
        if (input.value > 5000000 && input.value <= 10000000) {
          const sum1 = 1000000;
          const sum2 = 4000000;
          const sum3 = input.value - 5000000;
          const sum = (sum1 * 0.015 + sum2 * 0.008 + sum3 * 0.0045).toFixed(2);
          exp3.value = sum;
          tableData1.length = 0;
          tableData1.push({
            jiage: "100万",
            zbj: "1000000",
            gongs: sum1 + "*" + "1.5%" + "=" + sum1 * 0.015,
            jieguo: sum1 * 0.015
          });
          tableData1.push({
            jiage: "100-500万",
            zbj: sum2,
            gongs: 4000000 + "*" + "0.8%" + "=" + 4000000 * 0.008,
            jieguo: 4000000 * 0.008
          });
          tableData1.push({
            jiage: "500-1000万",
            zbj: sum3,
            gongs: sum3 + "*" + "0.45%" + "=" + sum3 * 0.0045,
            jieguo: sum3 * 0.0045
          });
          tableData1.push({
            jiage: "中标价格",
            zbj: input.value,
            gongs: "合计",
            jieguo: sum
          });
        }
        if (input.value > 10000000 && input.value <= 50000000) {
          const sum1 = 1000000;
          const sum2 = 4000000;
          const sum3 = 5000000;
          const sum4 = input.value - 10000000;
          const sum = (sum1 * 0.015 + sum2 * 0.008 + sum3 * 0.0045 + sum4 * 0.0025).toFixed(2);
          exp3.value = sum;
          tableData1.length = 0;
          tableData1.push({
            jiage: "100万",
            zbj: "1000000",
            gongs: sum1 + "*" + "1.5%" + "=" + sum1 * 0.015,
            jieguo: sum1 * 0.015
          });
          tableData1.push({
            jiage: "100-500万",
            zbj: sum2,
            gongs: 4000000 + "*" + "0.8%" + "=" + 4000000 * 0.008,
            jieguo: 4000000 * 0.008
          });
          tableData1.push({
            jiage: "500-1000万",
            zbj: sum3,
            gongs: sum3 + "*" + "0.45%" + "=" + sum3 * 0.0045,
            jieguo: sum3 * 0.0045
          });
          tableData1.push({
            jiage: "1000-5000万",
            zbj: sum4,
            gongs: sum4 + "*" + "0.25%" + "=" + sum4 * 0.0025,
            jieguo: sum4 * 0.0025
          });
          tableData1.push({
            jiage: "中标价格",
            zbj: input.value,
            gongs: "合计",
            jieguo: sum
          });
        }
        if (input.value > 50000000 && input.value <= 100000000) {
          const sum1 = 1000000;
          const sum2 = 4000000;
          const sum3 = 5000000;
          const sum4 = 40000000;
          const sum5 = input.value - 50000000;
          const sum = (sum1 * 0.015 + sum2 * 0.008 + sum3 * 0.0045 + sum4 * 0.0025 + sum5 * 0.001).toFixed(2);
          exp3.value = sum;
          tableData1.length = 0;
          tableData1.push({
            jiage: "100万",
            zbj: "1000000",
            gongs: sum1 + "*" + "1.5%" + "=" + sum1 * 0.015,
            jieguo: sum1 * 0.015
          });
          tableData1.push({
            jiage: "100-500万",
            zbj: sum2,
            gongs: 4000000 + "*" + "0.8%" + "=" + 4000000 * 0.008,
            jieguo: 4000000 * 0.008
          });
          tableData1.push({
            jiage: "500-1000万",
            zbj: sum3,
            gongs: sum3 + "*" + "0.45%" + "=" + sum3 * 0.0045,
            jieguo: sum3 * 0.0045
          });
          tableData1.push({
            jiage: "1000-5000万",
            zbj: sum4,
            gongs: sum4 + "*" + "0.25%" + "=" + sum4 * 0.0025,
            jieguo: sum4 * 0.0025
          });
          tableData1.push({
            jiage: "5000-10000万",
            zbj: sum5,
            gongs: sum5 + "*" + "0.1%" + "=" + sum5 * 0.001,
            jieguo: sum5 * 0.001
          });
          tableData1.push({
            jiage: "中标价格",
            zbj: input.value,
            gongs: "合计",
            jieguo: sum
          });
        }
        if (input.value > 100000000 && input.value <= 500000000) {
          const sum1 = 1000000;
          const sum2 = 4000000;
          const sum3 = 5000000;
          const sum4 = 40000000;
          const sum5 = 50000000;
          const sum6 = input.value - 100000000;
          const sum = (sum1 * 0.015 + sum2 * 0.008 + sum3 * 0.0045 + sum4 * 0.0025 + sum5 * 0.001 + sum6 * 0.0005).toFixed(2);
          exp3.value = sum;
          tableData1.length = 0;
          tableData1.push({
            jiage: "100万",
            zbj: "1000000",
            gongs: sum1 + "*" + "1.5%" + "=" + sum1 * 0.015,
            jieguo: sum1 * 0.015
          });
          tableData1.push({
            jiage: "100-500万",
            zbj: sum2,
            gongs: 4000000 + "*" + "0.8%" + "=" + 4000000 * 0.008,
            jieguo: 4000000 * 0.008
          });
          tableData1.push({
            jiage: "500-1000万",
            zbj: sum3,
            gongs: sum3 + "*" + "0.45%" + "=" + sum3 * 0.0045,
            jieguo: sum3 * 0.0045
          });
          tableData1.push({
            jiage: "1000-5000万",
            zbj: sum4,
            gongs: sum4 + "*" + "0.25%" + "=" + sum4 * 0.0025,
            jieguo: sum4 * 0.0025
          });
          tableData1.push({
            jiage: "5000-10000万",
            zbj: sum5,
            gongs: sum5 + "*" + "0.1%" + "=" + sum5 * 0.001,
            jieguo: sum5 * 0.001
          });
          tableData1.push({
            jiage: "10000-50000万",
            zbj: sum6,
            gongs: sum6 + "*" + "0.05%" + "=" + sum6 * 0.0005,
            jieguo: sum6 * 0.0005
          });
          tableData1.push({
            jiage: "中标价格",
            zbj: input.value,
            gongs: "合计",
            jieguo: sum
          });
        }
        if (input.value > 500000000 && input.value <= 10000000000) {
          const sum1 = 1000000;
          const sum2 = 4000000;
          const sum3 = 5000000;
          const sum4 = 40000000;
          const sum5 = 50000000;
          const sum6 = 400000000;
          const sum7 = input.value - 500000000;
          const sum = (sum1 * 0.015 + sum2 * 0.008 + sum3 * 0.0045 + sum4 * 0.0025 + sum5 * 0.001 + sum6 * 0.0005 + sum7 * 0.00035).toFixed(2);
          exp3.value = sum;
          tableData1.length = 0;
          tableData1.push({
            jiage: "100万",
            zbj: "1000000",
            gongs: sum1 + "*" + "1.5%" + "=" + sum1 * 0.015,
            jieguo: sum1 * 0.015
          });
          tableData1.push({
            jiage: "100-500万",
            zbj: sum2,
            gongs: 4000000 + "*" + "0.8%" + "=" + 4000000 * 0.008,
            jieguo: 4000000 * 0.008
          });
          tableData1.push({
            jiage: "500-1000万",
            zbj: sum3,
            gongs: sum3 + "*" + "0.45%" + "=" + sum3 * 0.0045,
            jieguo: sum3 * 0.0045
          });
          tableData1.push({
            jiage: "1000-5000万",
            zbj: sum4,
            gongs: sum4 + "*" + "0.25%" + "=" + sum4 * 0.0025,
            jieguo: sum4 * 0.0025
          });
          tableData1.push({
            jiage: "5000-10000万",
            zbj: sum5,
            gongs: sum5 + "*" + "0.1%" + "=" + sum5 * 0.001,
            jieguo: sum5 * 0.001
          });
          tableData1.push({
            jiage: "10000-50000万",
            zbj: sum6,
            gongs: sum6 + "*" + "0.05%" + "=" + sum6 * 0.0005,
            jieguo: sum6 * 0.0005
          });
          tableData1.push({
            jiage: "50000-100000万",
            zbj: sum7,
            gongs: sum7 + "*" + "0.035%" + "=" + sum7 * 0.00035,
            jieguo: sum7 * 0.00035
          });
          tableData1.push({
            jiage: "中标价格",
            zbj: input.value,
            gongs: "合计",
            jieguo: sum
          });
        }
        if (input.value > 1000000000 && input.value <= 50000000000) {
          const sum1 = 1000000;
          const sum2 = 4000000;
          const sum3 = 5000000;
          const sum4 = 40000000;
          const sum5 = 50000000;
          const sum6 = 400000000;
          const sum7 = 500000000;
          const sum8 = input.value - 1000000000;
          const sum = (sum1 * 0.015 + sum2 * 0.008 + sum3 * 0.0045 + sum4 * 0.0025 + sum5 * 0.001 + sum6 * 0.0005 + sum7 * 0.00035 + sum8 * 0.00008).toFixed(2);
          exp3.value = sum;
          tableData1.length = 0;
          tableData1.push({
            jiage: "100万",
            zbj: "1000000",
            gongs: sum1 + "*" + "1.5%" + "=" + sum1 * 0.015,
            jieguo: sum1 * 0.015
          });
          tableData1.push({
            jiage: "100-500万",
            zbj: sum2,
            gongs: 4000000 + "*" + "0.8%" + "=" + 4000000 * 0.008,
            jieguo: 4000000 * 0.008
          });
          tableData1.push({
            jiage: "500-1000万",
            zbj: sum3,
            gongs: sum3 + "*" + "0.45%" + "=" + sum3 * 0.0045,
            jieguo: sum3 * 0.0045
          });
          tableData1.push({
            jiage: "1000-5000万",
            zbj: sum4,
            gongs: sum4 + "*" + "0.25%" + "=" + sum4 * 0.0025,
            jieguo: sum4 * 0.0025
          });
          tableData1.push({
            jiage: "5000-10000万",
            zbj: sum5,
            gongs: sum5 + "*" + "0.1%" + "=" + sum5 * 0.001,
            jieguo: sum5 * 0.001
          });
          tableData1.push({
            jiage: "10000-50000万",
            zbj: sum6,
            gongs: sum6 + "*" + "0.05%" + "=" + sum6 * 0.0005,
            jieguo: sum6 * 0.0005
          });
          tableData1.push({
            jiage: "50000-100000万",
            zbj: sum7,
            gongs: sum7 + "*" + "0.035%" + "=" + sum7 * 0.00035,
            jieguo: sum7 * 0.00035
          });
          tableData1.push({
            jiage: "100000-500000万",
            zbj: sum8,
            gongs: sum8 + "*" + "0.008%" + "=" + sum8 * 0.00008,
            jieguo: sum8 * 0.00008
          });
          tableData1.push({
            jiage: "中标价格",
            zbj: input.value,
            gongs: "合计",
            jieguo: sum
          });
        }
        if (input.value > 5000000000 && input.value <= 10000000000) {
          const sum1 = 1000000;
          const sum2 = 4000000;
          const sum3 = 5000000;
          const sum4 = 40000000;
          const sum5 = 50000000;
          const sum6 = 400000000;
          const sum7 = 500000000;
          const sum8 = 4000000000;
          const sum9 = input.value - 5000000000;
          const sum = (sum1 * 0.015 + sum2 * 0.008 + sum3 * 0.0045 + sum4 * 0.0025 + sum5 * 0.001 + sum6 * 0.0005 + sum7 * 0.00035 + sum8 * 0.00008 + sum9 * 0.00006).toFixed(2);
          exp3.value = sum;
          tableData1.length = 0;
          tableData1.push({
            jiage: "100万",
            zbj: "1000000",
            gongs: sum1 + "*" + "1.5%" + "=" + sum1 * 0.015,
            jieguo: sum1 * 0.015
          });
          tableData1.push({
            jiage: "100-500万",
            zbj: sum2,
            gongs: 4000000 + "*" + "0.8%" + "=" + 4000000 * 0.008,
            jieguo: 4000000 * 0.008
          });
          tableData1.push({
            jiage: "500-1000万",
            zbj: sum3,
            gongs: sum3 + "*" + "0.45%" + "=" + sum3 * 0.0045,
            jieguo: sum3 * 0.0045
          });
          tableData1.push({
            jiage: "1000-5000万",
            zbj: sum4,
            gongs: sum4 + "*" + "0.25%" + "=" + sum4 * 0.0025,
            jieguo: sum4 * 0.0025
          });
          tableData1.push({
            jiage: "5000-10000万",
            zbj: sum5,
            gongs: sum5 + "*" + "0.1%" + "=" + sum5 * 0.001,
            jieguo: sum5 * 0.001
          });
          tableData1.push({
            jiage: "10000-50000万",
            zbj: sum6,
            gongs: sum6 + "*" + "0.05%" + "=" + sum6 * 0.0005,
            jieguo: sum6 * 0.0005
          });
          tableData1.push({
            jiage: "50000-100000万",
            zbj: sum7,
            gongs: sum7 + "*" + "0.035%" + "=" + sum7 * 0.00035,
            jieguo: sum7 * 0.00035
          });
          tableData1.push({
            jiage: "100000-500000万",
            zbj: sum8,
            gongs: sum8 + "*" + "0.008%" + "=" + sum8 * 0.00008,
            jieguo: sum8 * 0.00008
          });
          tableData1.push({
            jiage: "1500000-1000000万",
            zbj: sum9,
            gongs: sum9 + "*" + "0.006%" + "=" + sum9 * 0.00006,
            jieguo: sum9 * 0.00006
          });
          tableData1.push({
            jiage: "中标价格",
            zbj: input.value,
            gongs: "合计",
            jieguo: sum
          });
        }
        if (input.value > 10000000000) {
          const sum1 = 1000000;
          const sum2 = 4000000;
          const sum3 = 5000000;
          const sum4 = 40000000;
          const sum5 = 50000000;
          const sum6 = 400000000;
          const sum7 = 500000000;
          const sum8 = 4000000000;
          const sum9 = 5000000000;
          const sum10 = input.value - 10000000000;
          const sum = (sum1 * 0.015 + sum2 * 0.008 + sum3 * 0.0045 + sum4 * 0.0025 + sum5 * 0.001 + sum6 * 0.0005 + sum7 * 0.00035 + sum8 * 0.00008 + sum9 * 0.00006 + sum10 * 0.00004).toFixed(2);
          if (sum >= 3000000) {
            exp3.value = 3000000;
            console.log(311111);
          } else {
            exp3.value = sum;
          }
          tableData1.length = 0;
          tableData1.push({
            jiage: "100万",
            zbj: "1000000",
            gongs: sum1 + "*" + "1.5%" + "=" + sum1 * 0.015,
            jieguo: sum1 * 0.015
          });
          tableData1.push({
            jiage: "100-500万",
            zbj: sum2,
            gongs: 4000000 + "*" + "0.8%" + "=" + 4000000 * 0.008,
            jieguo: 4000000 * 0.008
          });
          tableData1.push({
            jiage: "500-1000万",
            zbj: sum3,
            gongs: sum3 + "*" + "0.45%" + "=" + sum3 * 0.0045,
            jieguo: sum3 * 0.0045
          });
          tableData1.push({
            jiage: "1000-5000万",
            zbj: sum4,
            gongs: sum4 + "*" + "0.25%" + "=" + sum4 * 0.0025,
            jieguo: sum4 * 0.0025
          });
          tableData1.push({
            jiage: "5000-10000万",
            zbj: sum5,
            gongs: sum5 + "*" + "0.1%" + "=" + sum5 * 0.001,
            jieguo: sum5 * 0.001
          });
          tableData1.push({
            jiage: "10000-50000万",
            zbj: sum6,
            gongs: sum6 + "*" + "0.05%" + "=" + sum6 * 0.0005,
            jieguo: sum6 * 0.0005
          });
          tableData1.push({
            jiage: "50000-100000万",
            zbj: sum7,
            gongs: sum7 + "*" + "0.035%" + "=" + sum7 * 0.00035,
            jieguo: sum7 * 0.00035
          });
          tableData1.push({
            jiage: "100000-500000万",
            zbj: sum8,
            gongs: sum8 + "*" + "0.008%" + "=" + sum8 * 0.00008,
            jieguo: sum8 * 0.00008
          });
          tableData1.push({
            jiage: "1500000-1000000万",
            zbj: sum9,
            gongs: sum9 + "*" + "0.006%" + "=" + sum9 * 0.00006,
            jieguo: sum9 * 0.00006
          });
          tableData1.push({
            jiage: "1000000万以上",
            zbj: sum10,
            gongs: sum10 + "*" + "0.004%" + "=" + sum10 * 0.00004,
            jieguo: sum10 * 0.00004
          });
          tableData1.push({
            jiage: "中标价格",
            zbj: input.value,
            gongs: "合计",
            jieguo: sum
          });
        }
      } else if (radio.value == "9") {
        if (input.value <= 1000000) {
          let sum = (input.value * 0.015).toFixed(2);
          exp3.value = sum;
          tableData1.length = 0;
          tableData1.push({
            jiage: "100万以下",
            zbj: input.value,
            gongs: input.value + "*" + "1.5%" + "=" + sum,
            jieguo: sum
          });
        }
        if (input.value > 1000000 && input.value <= 5000000) {
          let sum1 = input.value - 1000000;
          let sum = (1000000 * 0.015 + sum1 * 0.011).toFixed(2);
          exp3.value = sum;
          tableData1.length = 0;
          tableData1.push({
            jiage: "100万",
            zbj: "1000000",
            gongs: 1000000 + "*" + "1.5%" + "=" + 1000000 * 0.015,
            jieguo: 1000000 * 0.015
          });
          tableData1.push({
            jiage: "100-500万",
            zbj: sum1,
            gongs: sum1 + "*" + "1.1%" + "=" + sum1 * 0.011,
            jieguo: sum1 * 0.011
          });
          tableData1.push({
            jiage: "中标价格",
            zbj: input.value,
            gongs: "合计",
            jieguo: sum
          });
        }
        if (input.value > 5000000 && input.value <= 10000000) {
          const sum1 = 1000000;
          const sum2 = 4000000;
          const sum3 = input.value - 5000000;
          const sum = (sum1 * 0.015 + sum2 * 0.011 + sum3 * 0.008).toFixed(2);
          exp3.value = sum;
          tableData1.length = 0;
          tableData1.push({
            jiage: "100万",
            zbj: "1000000",
            gongs: 1000000 + "*" + "1.5%" + "=" + 1000000 * 0.015,
            jieguo: 1000000 * 0.015
          });
          tableData1.push({
            jiage: "100-500万",
            zbj: sum2,
            gongs: sum2 + "*" + "1.1%" + "=" + sum2 * 0.011,
            jieguo: sum2 * 0.011
          });
          tableData1.push({
            jiage: "500-1000万",
            zbj: sum3,
            gongs: sum3 + "*" + "0.8%" + "=" + sum3 * 0.008,
            jieguo: sum3 * 0.008
          });
          tableData1.push({
            jiage: "中标价格",
            zbj: input.value,
            gongs: "合计",
            jieguo: sum
          });
        }
        if (input.value > 10000000 && input.value <= 50000000) {
          const sum1 = 1000000;
          const sum2 = 4000000;
          const sum3 = 5000000;
          const sum4 = input.value - 10000000;
          const sum = (sum1 * 0.015 + sum2 * 0.011 + sum3 * 0.008 + sum4 * 0.005).toFixed(2);
          exp3.value = sum;
          tableData1.length = 0;
          tableData1.push({
            jiage: "100万",
            zbj: "1000000",
            gongs: 1000000 + "*" + "1.5%" + "=" + 1000000 * 0.015,
            jieguo: 1000000 * 0.015
          });
          tableData1.push({
            jiage: "100-500万",
            zbj: sum2,
            gongs: sum2 + "*" + "1.1%" + "=" + sum2 * 0.011,
            jieguo: sum2 * 0.011
          });
          tableData1.push({
            jiage: "500-1000万",
            zbj: sum3,
            gongs: sum3 + "*" + "0.8%" + "=" + sum3 * 0.008,
            jieguo: sum3 * 0.008
          });
          tableData1.push({
            jiage: "1000-5000万",
            zbj: sum4,
            gongs: sum4 + "*" + "0.5%" + "=" + sum4 * 0.005,
            jieguo: sum4 * 0.005
          });
          tableData1.push({
            jiage: "中标价格",
            zbj: input.value,
            gongs: "合计",
            jieguo: sum
          });
        }
        if (input.value > 50000000 && input.value <= 100000000) {
          const sum1 = 1000000;
          const sum2 = 4000000;
          const sum3 = 5000000;
          const sum4 = 40000000;
          const sum5 = input.value - 50000000;
          const sum = (sum1 * 0.015 + sum2 * 0.011 + sum3 * 0.008 + sum4 * 0.005 + sum5 * 0.0025).toFixed(2);
          exp3.value = sum;
          tableData1.length = 0;
          tableData1.push({
            jiage: "100万",
            zbj: "1000000",
            gongs: 1000000 + "*" + "1.5%" + "=" + 1000000 * 0.015,
            jieguo: 1000000 * 0.015
          });
          tableData1.push({
            jiage: "100-500万",
            zbj: sum2,
            gongs: sum2 + "*" + "1.1%" + "=" + sum2 * 0.011,
            jieguo: sum2 * 0.011
          });
          tableData1.push({
            jiage: "500-1000万",
            zbj: sum3,
            gongs: sum3 + "*" + "0.8%" + "=" + sum3 * 0.008,
            jieguo: sum3 * 0.008
          });
          tableData1.push({
            jiage: "1000-5000万",
            zbj: sum4,
            gongs: sum4 + "*" + "0.5%" + "=" + sum4 * 0.005,
            jieguo: sum4 * 0.005
          });
          tableData1.push({
            jiage: "5000-10000万",
            zbj: sum5,
            gongs: sum5 + "*" + "0.25%" + "=" + sum5 * 0.0025,
            jieguo: sum5 * 0.0025
          });
          tableData1.push({
            jiage: "中标价格",
            zbj: input.value,
            gongs: "合计",
            jieguo: sum
          });
        }
        if (input.value > 100000000 && input.value <= 500000000) {
          const sum1 = 1000000;
          const sum2 = 4000000;
          const sum3 = 5000000;
          const sum4 = 40000000;
          const sum5 = 50000000;
          const sum6 = input.value - 100000000;
          const sum = (sum1 * 0.015 + sum2 * 0.011 + sum3 * 0.008 + sum4 * 0.005 + sum5 * 0.0025 + sum6 * 0.0005).toFixed(2);
          exp3.value = sum;
          tableData1.length = 0;
          tableData1.push({
            jiage: "100万",
            zbj: "1000000",
            gongs: 1000000 + "*" + "1.5%" + "=" + 1000000 * 0.015,
            jieguo: 1000000 * 0.015
          });
          tableData1.push({
            jiage: "100-500万",
            zbj: sum2,
            gongs: sum2 + "*" + "1.1%" + "=" + sum2 * 0.011,
            jieguo: sum2 * 0.011
          });
          tableData1.push({
            jiage: "500-1000万",
            zbj: sum3,
            gongs: sum3 + "*" + "0.8%" + "=" + sum3 * 0.008,
            jieguo: sum3 * 0.008
          });
          tableData1.push({
            jiage: "1000-5000万",
            zbj: sum4,
            gongs: sum4 + "*" + "0.5%" + "=" + sum4 * 0.005,
            jieguo: sum4 * 0.005
          });
          tableData1.push({
            jiage: "5000-10000万",
            zbj: sum5,
            gongs: sum5 + "*" + "0.25%" + "=" + sum5 * 0.0025,
            jieguo: sum5 * 0.0025
          });
          tableData1.push({
            jiage: "10000-50000万",
            zbj: sum6,
            gongs: sum6 + "*" + "0.05%" + "=" + sum6 * 0.0005,
            jieguo: sum6 * 0.0005
          });
          tableData1.push({
            jiage: "中标价格",
            zbj: input.value,
            gongs: "合计",
            jieguo: sum
          });
        }
        if (input.value > 500000000 && input.value <= 10000000000) {
          const sum1 = 1000000;
          const sum2 = 4000000;
          const sum3 = 5000000;
          const sum4 = 40000000;
          const sum5 = 50000000;
          const sum6 = 400000000;
          const sum7 = input.value - 500000000;
          const sum = (sum1 * 0.015 + sum2 * 0.011 + sum3 * 0.008 + sum4 * 0.005 + sum5 * 0.0025 + sum6 * 0.0005 + sum7 * 0.00035).toFixed(2);
          exp3.value = sum;
          tableData1.length = 0;
          tableData1.push({
            jiage: "100万",
            zbj: "1000000",
            gongs: 1000000 + "*" + "1.5%" + "=" + 1000000 * 0.015,
            jieguo: 1000000 * 0.015
          });
          tableData1.push({
            jiage: "100-500万",
            zbj: sum2,
            gongs: sum2 + "*" + "1.1%" + "=" + sum2 * 0.011,
            jieguo: sum2 * 0.011
          });
          tableData1.push({
            jiage: "500-1000万",
            zbj: sum3,
            gongs: sum3 + "*" + "0.8%" + "=" + sum3 * 0.008,
            jieguo: sum3 * 0.008
          });
          tableData1.push({
            jiage: "1000-5000万",
            zbj: sum4,
            gongs: sum4 + "*" + "0.5%" + "=" + sum4 * 0.005,
            jieguo: sum4 * 0.005
          });
          tableData1.push({
            jiage: "5000-10000万",
            zbj: sum5,
            gongs: sum5 + "*" + "0.25%" + "=" + sum5 * 0.0025,
            jieguo: sum5 * 0.0025
          });
          tableData1.push({
            jiage: "10000-50000万",
            zbj: sum6,
            gongs: sum6 + "*" + "0.05%" + "=" + sum6 * 0.0005,
            jieguo: sum6 * 0.0005
          });
          tableData1.push({
            jiage: "50000-100000万",
            zbj: sum7,
            gongs: sum7 + "*" + "0.035%" + "=" + sum7 * 0.00035,
            jieguo: sum7 * 0.00035
          });
          tableData1.push({
            jiage: "中标价格",
            zbj: input.value,
            gongs: "合计",
            jieguo: sum
          });
        }
        if (input.value > 1000000000 && input.value <= 50000000000) {
          const sum1 = 1000000;
          const sum2 = 4000000;
          const sum3 = 5000000;
          const sum4 = 40000000;
          const sum5 = 50000000;
          const sum6 = 400000000;
          const sum7 = 500000000;
          const sum8 = input.value - 1000000000;
          const sum = (sum1 * 0.015 + sum2 * 0.011 + sum3 * 0.008 + sum4 * 0.005 + sum5 * 0.0025 + sum6 * 0.0005 + sum7 * 0.00035 + sum8 * 0.00008).toFixed(2);
          exp3.value = sum;
          tableData1.length = 0;
          tableData1.push({
            jiage: "100万",
            zbj: "1000000",
            gongs: 1000000 + "*" + "1.5%" + "=" + 1000000 * 0.015,
            jieguo: 1000000 * 0.015
          });
          tableData1.push({
            jiage: "100-500万",
            zbj: sum2,
            gongs: sum2 + "*" + "1.1%" + "=" + sum2 * 0.011,
            jieguo: sum2 * 0.011
          });
          tableData1.push({
            jiage: "500-1000万",
            zbj: sum3,
            gongs: sum3 + "*" + "0.8%" + "=" + sum3 * 0.008,
            jieguo: sum3 * 0.008
          });
          tableData1.push({
            jiage: "1000-5000万",
            zbj: sum4,
            gongs: sum4 + "*" + "0.5%" + "=" + sum4 * 0.005,
            jieguo: sum4 * 0.005
          });
          tableData1.push({
            jiage: "5000-10000万",
            zbj: sum5,
            gongs: sum5 + "*" + "0.25%" + "=" + sum5 * 0.0025,
            jieguo: sum5 * 0.0025
          });
          tableData1.push({
            jiage: "10000-50000万",
            zbj: sum6,
            gongs: sum6 + "*" + "0.05%" + "=" + sum6 * 0.0005,
            jieguo: sum6 * 0.0005
          });
          tableData1.push({
            jiage: "50000-100000万",
            zbj: sum7,
            gongs: sum7 + "*" + "0.035%" + "=" + sum7 * 0.00035,
            jieguo: sum7 * 0.00035
          });
          tableData1.push({
            jiage: "100000-500000万",
            zbj: sum8,
            gongs: sum8 + "*" + "0.008%" + "=" + sum8 * 0.00008,
            jieguo: sum8 * 0.00008
          });
          tableData1.push({
            jiage: "中标价格",
            zbj: input.value,
            gongs: "合计",
            jieguo: sum
          });
        }
        if (input.value > 5000000000 && input.value <= 10000000000) {
          const sum1 = 1000000;
          const sum2 = 4000000;
          const sum3 = 5000000;
          const sum4 = 40000000;
          const sum5 = 50000000;
          const sum6 = 400000000;
          const sum7 = 500000000;
          const sum8 = 4000000000;
          const sum9 = input.value - 5000000000;
          const sum = (sum1 * 0.015 + sum2 * 0.011 + sum3 * 0.008 + sum4 * 0.005 + sum5 * 0.0025 + sum6 * 0.0005 + sum7 * 0.00035 + sum8 * 0.00008 + sum9 * 0.00006).toFixed(2);
          exp3.value = sum;
          tableData1.length = 0;
          tableData1.push({
            jiage: "100万",
            zbj: "1000000",
            gongs: 1000000 + "*" + "1.5%" + "=" + 1000000 * 0.015,
            jieguo: 1000000 * 0.015
          });
          tableData1.push({
            jiage: "100-500万",
            zbj: sum2,
            gongs: sum2 + "*" + "1.1%" + "=" + sum2 * 0.011,
            jieguo: sum2 * 0.011
          });
          tableData1.push({
            jiage: "500-1000万",
            zbj: sum3,
            gongs: sum3 + "*" + "0.8%" + "=" + sum3 * 0.008,
            jieguo: sum3 * 0.008
          });
          tableData1.push({
            jiage: "1000-5000万",
            zbj: sum4,
            gongs: sum4 + "*" + "0.5%" + "=" + sum4 * 0.005,
            jieguo: sum4 * 0.005
          });
          tableData1.push({
            jiage: "5000-10000万",
            zbj: sum5,
            gongs: sum5 + "*" + "0.25%" + "=" + sum5 * 0.0025,
            jieguo: sum5 * 0.0025
          });
          tableData1.push({
            jiage: "10000-50000万",
            zbj: sum6,
            gongs: sum6 + "*" + "0.05%" + "=" + sum6 * 0.0005,
            jieguo: sum6 * 0.0005
          });
          tableData1.push({
            jiage: "50000-100000万",
            zbj: sum7,
            gongs: sum7 + "*" + "0.035%" + "=" + sum7 * 0.00035,
            jieguo: sum7 * 0.00035
          });
          tableData1.push({
            jiage: "100000-500000万",
            zbj: sum8,
            gongs: sum8 + "*" + "0.008%" + "=" + sum8 * 0.00008,
            jieguo: sum8 * 0.00008
          });
          tableData1.push({
            jiage: "500000-1000000万",
            zbj: sum9,
            gongs: sum9 + "*" + "0.006%" + "=" + sum9 * 0.00006,
            jieguo: sum9 * 0.00006
          });
          tableData1.push({
            jiage: "中标价格",
            zbj: input.value,
            gongs: "合计",
            jieguo: sum
          });
        }
        if (input.value > 10000000000) {
          const sum1 = 1000000;
          const sum2 = 4000000;
          const sum3 = 5000000;
          const sum4 = 40000000;
          const sum5 = 50000000;
          const sum6 = 400000000;
          const sum7 = 500000000;
          const sum8 = 4000000000;
          const sum9 = 5000000000;
          const sum10 = input.value - 10000000000;
          const sum = (sum1 * 0.015 + sum2 * 0.011 + sum3 * 0.008 + sum4 * 0.005 + sum5 * 0.0025 + sum6 * 0.0005 + sum7 * 0.00035 + sum8 * 0.00008 + sum9 * 0.00006 + sum10 * 0.00004).toFixed(2);
          if (sum >= 3500000) {
            exp3.value = 3500000;
            console.log(sum);
          } else {
            exp3.value = sum;
          }
          tableData1.length = 0;
          tableData1.push({
            jiage: "100万",
            zbj: "1000000",
            gongs: 1000000 + "*" + "1.5%" + "=" + 1000000 * 0.015,
            jieguo: 1000000 * 0.015
          });
          tableData1.push({
            jiage: "100-500万",
            zbj: sum2,
            gongs: sum2 + "*" + "1.1%" + "=" + sum2 * 0.011,
            jieguo: sum2 * 0.011
          });
          tableData1.push({
            jiage: "500-1000万",
            zbj: sum3,
            gongs: sum3 + "*" + "0.8%" + "=" + sum3 * 0.008,
            jieguo: sum3 * 0.008
          });
          tableData1.push({
            jiage: "1000-5000万",
            zbj: sum4,
            gongs: sum4 + "*" + "0.5%" + "=" + sum4 * 0.005,
            jieguo: sum4 * 0.005
          });
          tableData1.push({
            jiage: "5000-10000万",
            zbj: sum5,
            gongs: sum5 + "*" + "0.25%" + "=" + sum5 * 0.0025,
            jieguo: sum5 * 0.0025
          });
          tableData1.push({
            jiage: "10000-50000万",
            zbj: sum6,
            gongs: sum6 + "*" + "0.05%" + "=" + sum6 * 0.0005,
            jieguo: sum6 * 0.0005
          });
          tableData1.push({
            jiage: "50000-100000万",
            zbj: sum7,
            gongs: sum7 + "*" + "0.035%" + "=" + sum7 * 0.00035,
            jieguo: sum7 * 0.00035
          });
          tableData1.push({
            jiage: "100000-500000万",
            zbj: sum8,
            gongs: sum8 + "*" + "0.008%" + "=" + sum8 * 0.00008,
            jieguo: sum8 * 0.00008
          });
          tableData1.push({
            jiage: "500000-1000000万",
            zbj: sum9,
            gongs: sum9 + "*" + "0.006%" + "=" + sum9 * 0.00006,
            jieguo: sum9 * 0.00006
          });
          tableData1.push({
            jiage: "1000000以上万",
            zbj: sum10,
            gongs: sum10 + "*" + "0.004%" + "=" + sum10 * 0.00004,
            jieguo: sum10 * 0.00004
          });
          tableData1.push({
            jiage: "中标价格",
            zbj: input.value,
            gongs: "合计",
            jieguo: sum
          });
        }
      }
      // exp3.value = sum
      // return exp3
    }

    return {
      input,
      radio,
      exp3,
      exp4,
      tableData,
      text1,
      tableData1
    };
  }
};