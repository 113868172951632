import zhCn from "/node_modules/element-plus/lib/locale/lang/zh-cn.js";
import { ElConfigProvider } from 'element-plus';
// import zhCn from "/node_modules/element-plus/lib/locale/lang/zh-cn.js"
// import zhCn from "../node_modules/element-plus/lib/locale/lang/zh-cn"; 
import { reactive, ref } from "vue";
const value = ref(new Date());
export default {
  components: {
    [ElConfigProvider.name]: ElConfigProvider
  },
  setup() {
    const value1 = ref('');
    const value2 = ref('');
    const locale = zhCn;
    const value = ref(new Date());
    const time1 = ref("O");
    function time2() {
      time1.value = Math.abs(Date.parse(value1.value) - Date.parse(value2.value)) / (1000 * 3600 * 24);
      // console.log(Date.parse(value1.value));
    }

    return {
      locale,
      value,
      value1,
      value2,
      time1,
      time2
    };
  }
};